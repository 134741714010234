import {Injectable, OnDestroy} from '@angular/core';
import { environment } from '../../environments/environment';
import { LogService } from './log.service';
import { RestService } from "./rest.service";
import { NavigationService } from "./navigation.service";
import { FormService } from "./form.service";
import { AlertService } from "./alert.service";
import { DomSanitizer } from "@angular/platform-browser";
import {ContextMenuService} from "./context-menu.service";
import {BubbleService} from "./bubble.service";
import {Structure} from "../_models/structure.model";
import {Refresh} from "../_models/refresh.model";
import {Element} from "../_models/element.model";
import {Action} from "../_models/action.model";
import {ListRow} from "../_models/list_row.model";
import {Detail} from "../_models/detail.model";
import {SiteModule} from "../_models/site_module.model";
import {Subscription} from "rxjs";
import {Location} from "@angular/common";
import {NavigationModalService} from "./navigation.modal.service";
import {NavigationOffcanvasService} from "./navigation.offcanvas.service";
import {Grid} from "../_models/grid.model";
import {GridListRow} from "../_models/grid_list_row.model";
import {PusherService} from "./pusher.service";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy {
  // ENVIRONMENT
  environment = environment;
  date: Date = new Date();
  object = Object;
  array = Array;
  // LOADING
  //
  splash: boolean = true;
  structure: Structure = null;
  //
  _wrappers: Subscription[] = [];
  // FUNCTIONS
  // functions: Functions = Functions;
  // formButtonFunctions: FormButtonFunctions = new FormButtonFunctions();
  // DISPLAY
  // display: Display = Display;
  // MAIN LOADER
  // loader: boolean = true;
  // PAGE TYPE
  // page_type: string = 'page';
  // Transfer data
  // data: any = null;

  constructor(
      public sanitizer: DomSanitizer,
      // OTHERS SERVICES
      public authS: AuthService,
      public navigationS: NavigationService,
      public alertS: AlertService,
      public restS: RestService,
      public formS: FormService,
      public bubbleS: BubbleService,
      public contextS: ContextMenuService,
      public logS: LogService,
      private modalS: NavigationModalService,
      private offcanvasS: NavigationOffcanvasService,
      //
      private location: Location,
  ) {
    this.logS.log_construct('service', 'APP');

    // NAVIGATION (SUIVANT / PRECEDENT) BINDING -> ELSE NOTHING HAPPEN WHEN CLICK ON PREVIOUS/FOLLOW BUTTON FROM NAVIGATOR
    location.subscribe(val => {
      const parts = val.url.split('/');
      parts.shift();
      this.navigationS.navigate('page', 'main', 'A1', parts);
    });
  }

  ngOnDestroy() {
    this.structure = null;
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  sanitize(url: string) {
    return url ? this.sanitizer.bypassSecurityTrustUrl(url) : url;
  }
  sanitizeRessource(url: string) {
    return url ? this.sanitizer.bypassSecurityTrustResourceUrl(url) : url;
  }
  sanitizeHTML(url: string) {
    return url ? this.sanitizer.bypassSecurityTrustHtml(url) : url;
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  logout() {
    // CLEAR ALERT
    this.alertS.clear();
    // SPLASH
    this.toggleSplash(true, 0);
    // CLEAR STRUCTURE
    this.structure = null;
    // LOGOUT
    setTimeout(() => { this.authS.logout(); }, 1000);
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  checkRefresh(refresh: Refresh) {
    this.logS.log_dev(refresh);
    if (this.structure?.displayeds) {
      Object.keys(this.structure?.displayeds).forEach(key_displayed => {
        this.checkRefreshGrids(this.structure.displayeds[key_displayed].grids, refresh);
      });
    }
  }
  checkRefreshGrids(grids: Grid[], refresh: Refresh) {
    grids?.forEach(grid => {
      grid.blocs?.forEach(bloc => {
        bloc.elements?.forEach(element => {
          this.checkRefreshRessource(element, refresh);
          Object.keys(element.details).forEach(key => {
            this.checkRefreshRessource(element.details[key], refresh, element);
          });
          element.list?.rows.forEach(row => {
            this.checkRefreshRessource(row, refresh, element);
            Object.keys(row.details).forEach(key => {
              this.checkRefreshRessource(row.details[key], refresh);
            });
          });
          element.grid_list?.rows.forEach(row => {
            this.checkRefreshRessource(row, refresh, element);
            this.checkRefreshGrids(row.grids, refresh);
          });
        });
      });
    });
  }
  checkRefreshRessource(ressource, refresh: Refresh, ressource2 = null) {
    // console.log('CHECK REFRESH ' + ressource.unique_id);
    if (ressource.unique_id === refresh.ressource_unique_id) {
      // console.log(ressource.constructor.name);
      if (ressource instanceof Element) {
        this.refreshElement(ressource, refresh);
      } else if (ressource instanceof Detail) {
        this.refreshDetail(ressource, refresh, ressource2);
      } else if (ressource instanceof ListRow) {
        this.refreshListRow(ressource, refresh, ressource2);
      } else if (ressource instanceof GridListRow) {
        this.refreshListRow(ressource, refresh, ressource2);
      } else {
        alert('APP > not managed');
      }
    }
  }

  refreshWrapper(type: string, wrapper: string) {
    // IS ACTION ?
    if (!this.navigationS.navigations[type + '.' + wrapper].route) {
      return;
    }
    this.logS.log_dev('REFRESH WRAPPER ' + type + '.' + wrapper);
    // CACHE
    // this.structure.cache(type, wrapper, this.navigationS.navigations[type + '.' + wrapper].step, this.navigationS.navigations[type + '.' + wrapper].route_full)
    // RESET
    this.structure.reset(type, wrapper, this.navigationS.navigations[type + '.' + wrapper].step);
    this._wrappers[type + '.' + wrapper]?.unsubscribe();
    // GET GRID
    this._wrappers[type + '.' + wrapper] = this.restS.post('grid/elements', { route: this.navigationS.navigations[type + '.' + wrapper].route_full }).subscribe(response => {
      if (response?.success) {
        // DISPLAYED
        this.structure.setDisplayeds(type, wrapper, response.displayed, this.navigationS.navigations[type + '.' + wrapper].route_full);
        // MENUS A ACTIVES
        this.structure.setMenuAActives(type, wrapper, response.menus_a);
        // ROOT
        this.structure.setRoots(type, wrapper, response.root, this.navigationS.navigations[type + '.' + wrapper].route_full);
        // MENUS C
        this.structure.setMenu(type, wrapper, response.menus_c, 'c', this.navigationS.navigations[type + '.' + wrapper].route_full);
        // INIT LISTS
        // setTimeout(() => { this.listS.initialize() }, 100);
        // UPDATE SIZE
        if (type === 'modal' && !this.modalS.modal[wrapper]['custom_size']) {
          this.modalS.modal[wrapper]._windowCmptRef.instance.modalDialogClass = this.structure.displayeds[type + '.' + wrapper]?.size ?? 'w-7';
        } else if (type === 'offcanvas' && !this.offcanvasS.offcanvas[wrapper]['custom_size']) {
          this.offcanvasS.offcanvas[wrapper]._panelCmptRef.instance.panelClass = this.structure.displayeds[type + '.' + wrapper]?.size ?? this.structure.roots[type + '.' + wrapper]?.size ?? 'w-5';
        }
      } else {
        this.logS.log_response_error(response?.errors);
        this.structure.errors[type + '.' + wrapper] = response?.errors.code;
      }
      // RESET STEP
      // this.structure.loading_cache[type + '.' + wrapper] = false;
      this.navigationS.navigations[type + '.' + wrapper].step = null;
      console.log(this.structure);
    });
  }

  refreshElement(element: Element, refresh: Refresh) {
    this.logS.log_dev('REFRESH ELEMENT #' + element.unique_id);
    // LOADING > ON
    element.loading_refresh = true;
    // ADD ROW
    if (element.type?.name === 'list' && refresh.type === 'new') {
      this.restS.post('grid/row', { element_id: element.id, entity_id: element.entity_id, row_id: (refresh.entity_id) }).subscribe(response => {
        if (response.success) {
          element.list ? element.list.addRow(response.row, element) : element.grid_list.addRow(response.grid_rows, element);
        }
        // LOADING > OFF
        element.loading_refresh = false;
      });
    }
    // REMOVE ROW
    else if (element.type?.name === 'list' && refresh.type === 'delete') {
      element.list ? element.list.removeRow(refresh.entity_id) : element.grid_list.removeRow(refresh.entity_id);
      // LOADING > OFF
      element.loading_refresh = false;
    }
    // GET ELEMENT
    else {
      this.restS.post('grid/element', { element_id: element.id, entity_id: element.entity_id }).subscribe(response => {
        if (response.success) {
          element.update(response.element);
          // BUBBLE
          // appS.bubbleS.updateSiteModuleBubble(this.element);
        }
        // LOADING > OFF
        element.loading_refresh = false;
      });
    }
  }

  refreshDetail(detail: Detail, refresh: Refresh, element: Element) {
    this.logS.log_dev('REFRESH DETAIL #' + detail.unique_id);
    // LOADING > ON
    detail.loading_refresh = true;
    // GET DETAIL
    this.restS.post('grid/detail', { detail_id: detail.id, entity_id: detail.ressource_id }).subscribe(response => {
      if (response.success) {
        detail.update(response.detail, element);
      }
      // LOADING > OFF
      detail.loading_refresh = false;
    });
  }

  refreshListRow(row: ListRow|GridListRow, refresh: Refresh, element: Element) {
    this.logS.log_dev('REFRESH LIST ROW #' + row.unique_id);
    // LOADING > ON
    element.loading_refresh = true;
    // REMOVE ROW
    if (element.type?.name === 'list' && refresh.type === 'delete') {
      element.list ? element.list.removeRow(refresh.entity_id) : element.grid_list.removeRow(refresh.entity_id);
      // LOADING > OFF
      element.loading_refresh = false;
    }
    // UPDATE ROW
    else if (element.type?.name === 'list' && refresh.type === 'update') {
      if (refresh.direct) {
        row.loading = true;
      } else {
        row.loading_refresh = true;
      }
      this.restS.post('grid/row', { element_id: element.id, entity_id: element.entity_id, row_id: (refresh.entity_id) }).subscribe(response => {
        if (response.success) {
          element.list ? element.list.updateRow(response.row, element) : element.grid_list.updateRow(response.row, element);
        }
        // LOADING > OFF
        element.loading_refresh = false;
        row.loading = false;
        row.loading_refresh = false;
      });
    }
  }

  refreshAction(action, refresh: Refresh) {
    console.log('REFRESH ACTION #' + action.unique_id);
    // TODO A VERIFIER
    this.restS.post('grid/action', { action_id: action.id, entity_id: action.ressource_id }).subscribe(response => {
      if (response.success) {
        action.update(response.action);
      }
    });
  }

  /** --------------------------------------------------------------------------------------------------------------- */

  toggleSplash(value: boolean = null, timeout: number = 2000) {
    setTimeout(() => { this.splash = value ?? !this.splash; }, timeout);
  }
}
