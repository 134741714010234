import {Functions} from "../_functions/functions";
import {Type} from "./type.model";

export class Value {
  type: Type
  value;
  classes: string = '';
  search: string;
  sort: string;
  filter: {};

  constructor(datas) {
    Object.keys(datas).forEach(key => {
      switch (key) {
        case 'type':
          this.type = new Type(datas[key]);
          break;
        case 'value':
          this.value = datas[key];
          break;
        case 'classes':
          datas[key]?.forEach(data => {
            this.classes += data + ' ';
          });
          break;
        case 'search':
        case 'sort':
          this[key] = Functions.slugify(datas[key], true).toLowerCase();
          break;
        case 'filter':
          alert('TO DO');
        default:
          console.warn('Not managed: ' + key);
      }
    });
  }

  /* ------------------------------------------------------------------------------------------------------------------ */

  delete() {
    this.value = null;
    this.classes = null;
    this.search = null;
    this.sort = null;
    this.filter = null;
  }
}
